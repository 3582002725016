.wrapper {
  display: flex;
  flex-direction: column;
  width: 12px;
  padding-bottom: 25px;
}

.fill {
  --color-indicator-flex: 1;
  flex: var(--color-indicator-flex);
  width: 100%;
  transition: 0.4s;
}

.topBlock {
  height: 50px;
  box-shadow: inset 0px 45px 20px -20px #ffffff;
  transition: 0.4s;
}

.topBlock.gone {
  height: 0px;
}

.red {
  background-color: #e4a6a6;
}

.amber {
  background-color: #f1ebce;
}
