.page {
  min-height: 100vh;

  /*https://stackoverflow.com/questions/8468066/child-inside-parent-with-min-height-100-not-inheriting-height*/
  height: 1px;
}

.header {
  background-color: var(--torq-experimental-primary-color);
  padding: var(--torq-experimental-rhythm-sm);
  color: white;
}

.header a {
  color: #98bfec;
}

.header a:hover {
  color: #bbdbff;
}

.breadcrumbsWrapper {
  padding: var(--torq-experimental-rhythm-sm) var(--torq-experimental-rhythm-lg);
  background-origin: white;
  border-bottom: 1px solid #ccd9e7;
}

.breadcrumbs a,
.breadcrumbs :global(.ant-breadcrumb-separator) {
  font-size: 18px;
}

.body {
  background-color: #fafafa;
  color: var(--torq-experimental-primary-color);
  height: 100%;
  padding: var(--torq-experimental-rhythm-sm) var(--torq-experimental-rhythm-lg);
}

.userMenuItemIcon {
  margin-right: 5px;
}

.userMenuItem {
  margin-right: 16px;
}

.userPanel {
  color: white;
  width: 100%;
  padding-right: var(--torq-experimental-rhythm-xs);
}

.userLabel {
  font-size: 14px;
}

.smoothIcon {
  transition: 0.2s;
}
