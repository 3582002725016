.wire {
  background-color: #cfd9e4;
  width: 10px;
  height: 40px;
}

.bank {
  background-color: #e9eff3;
  width: 100%;
  height: 160px;
}

.droppable {
  border: 5px solid #cfd9e4;
  width: 100%;
  height: 100%;
  position: relative;
}

.label {
  position: absolute;
  top: 0;
  width: 100%;
  font-size: 0.75rem;
  font-style: italic;
  color: rgb(92, 92, 92);
  text-align: center;
  padding: var(--torq-experimental-rhythm-xs);

  /*This label was blocking the sensor link below it, so pointer events
    be gone*/
  pointer-events: none;
}

.whiteBackground {
  background-color: white;
}

.dropHover {
  padding: 0px;
  border: 5px dashed #9ba8b6;
}
