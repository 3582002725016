.title {
  font-size: 32px;
  font-weight: bold;
  color: var(--torq-experimental-primary-color);
}

.subTitle {
  font-size: 18px;
  color: rgb(158, 160, 190);
}
