.logoWrapper {
  background-color: var(--torq-experimental-primary-color);
  padding: 20px;
  border-radius: 15px;
  margin-bottom: 20px;
}

.splash {
  position: fixed;
  z-index: 5;
  width: 100vw;
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  transition: 0.3s;
  transition-delay: 0.1s;
  background-color: white;
}
