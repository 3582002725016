.tiny {
  font-size: 12px;
}

.dotted {
  text-decoration: underline dotted;
  cursor: default;
}

.fixedWidth {
  width: 90px;
}
