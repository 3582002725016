.subtle {
  font-size: 14px;
  color: #ccc;
  font-style: italic;
}

.strapLine {
  text-align: center;
}

.wrapper {
  padding: var(--torq-experimental-rhythm-lg);
  transition: 0.3s;
}

.wrapper:hover {
  background-color: #f6fafd;
}

.wrapper:last-child .divider {
  display: none;
}

.clickable {
  cursor: pointer;
}
