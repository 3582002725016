#globalLayout :global(.ant-layout-sider),
#globalLayout :global(.ant-layout-sider-trigger),
#globalLayout :global(.ant-menu.ant-menu-dark),
#globalLayout :global(.ant-layout-header) {
  background-color: #0c3361;
}

#globalLayout :global(.ant-menu-dark .ant-menu-item:hover) {
  background-color: var(--torq-experimental-success-color);
}

#globalLayout :global(.ant-menu-dark .ant-menu-item-selected) {
  background-color: unset;
}

#globalLayout :global(.ant-menu-dark .ant-menu-item-selected span) {
  color: rgba(255, 255, 255, 0.65);
}

.center {
  text-align: center;
}
