.addressField {
  padding-right: 20px;
  padding-bottom: 10px;
}

.addressFieldTitle {
  color: rgba(0, 0, 0, 0.45);
  font-size: 12px;
  line-height: 1.5715;
}

.compact .addressField {
  padding-right: 12px;
  padding-bottom: 6px;
}

.compact .addressFieldTitle {
  display: none;
}
