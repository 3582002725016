.tagLine,
.strapLine {
  color: #babbbe;
  font-size: 20px;
  font-style: italic;
}

.strapLine {
  font-size: 16px;
}

.details {
  max-height: 300px;
  overflow-y: auto;
}
