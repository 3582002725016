.wrapper {
  --hover-color: rgb(244, 245, 252);
  --selection-color: rgb(191, 201, 255);
  --last-clicked-border-color: rgb(150, 164, 240);
  --disabled-opacity: 0.5;

  transform-origin: center;
  transition: 0.08s transform ease-out, 0.3s background-color;
  user-select: none;
  padding: var(--torq-experimental-rhythm-xs);
}

.displayName {
  font-size: 1.05rem;
  line-height: 1rem;
}

.wrapper:hover {
  background-color: var(--hover-color);
  cursor: pointer;
}

.wrapper:hover:active {
  transform: scale(0.98);
}

.wrapper.selected {
  background-color: var(--selection-color);
}

.wrapper.disabled {
  opacity: var(--disabled-opacity);
  cursor: not-allowed;
  pointer-events: none;
}

.wrapper.lastClicked {
  padding: calc(var(--torq-experimental-rhythm-xs) - 1px);
  border: 1px solid var(--last-clicked-border-color);
}
