/*You'd think this would belong with the DashboardLayout seeing as it's what
 imports this thing, but seeing as this file is going to effect the whole
 dashboard, I figured I should put it somewhere obvious*/
#sentry-dashboard h1,
#sentry-dashboard h2,
#sentry-dashboard h3,
#sentry-dashboard h4,
#sentry-dashboard h5,
#sentry-dashboard h6 {
  color: var(--torq-experimental-primary-color);
  margin: 0px;
}
