.card {
  height: 100%;
}

.sider {
  background-color: white;
  padding: 16px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  border-right: 1px solid rgb(242, 242, 246);
  cursor: pointer;
}

.sider:hover {
  background-color: rgb(246, 248, 250);
}

.sider:active {
  background-color: rgb(236, 242, 245);
}

.body {
  background-color: white;
  width: 0px;
  overflow-x: hidden;
  transition: 0.4s;
}

.smoothIcon {
  transition: 0.2s;
}
