.icon {
  --letter-icon-color: white;
  --letter-icon-bg-color: transparent;

  color: var(--letter-icon-color);
  background-color: var(--letter-icon-bg-color);
  display: inline-block;
  border-radius: 100%;
  width: 3rem; /* 48px / 16px */
  min-width: 3rem;
  height: 3rem; /* 48px / 16px */
  padding: 0 0.125rem; /* 2px / 16px */
  font-size: 1.625rem; /* 26px / 16px */
  text-align: center;
  vertical-align: middle;
  line-height: 2.5rem;
  white-space: nowrap;
}

.icon.small {
  width: 2rem;
  height: 2rem;
  min-width: 2rem;
  font-size: 0.9rem;
  line-height: 2rem;
  padding: 0 0.125rem; /* 1px / 16px */
}
