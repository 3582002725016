#panel .reduceBottomMargin {
  margin-bottom: 4px;
}

#panel .addressCol {
  padding-right: 10px;
}

#panel .fullWidth {
  width: 100%;
}
