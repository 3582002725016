#chartWrapper {
  --configurer-width: 440px;
}

#charWrapper .ant-table-thead > tr > th {
  overflow-wrap: normal;
}

.timezoneSelect {
  font-weight: normal;
  width: 200px;
}

/* 
Not sure why this needs a fixed height?
#chartWrapper .fullHeight {
  height: 520px;
} */

.chart {
  width: 100%;
  transition: 0.1s;
}

.chart.compressed {
  width: calc(100% - var(--configurer-width));
}

.configurerWrapper {
  width: var(--configurer-width);
  transition: 0.1s;
}

.configurerWrapper.collapsed {
  width: 0px;
}

.configurer {
  width: calc(var(--configurer-width) - 60px);
  overflow-x: hidden;
  /* 
  Not sure why this needs a fixed height?
  height: 520px; */
}

.bottomBorder {
  border-bottom: 1px solid rgb(174, 174, 183);
}

.scrollable {
  overflow-y: auto;
}
