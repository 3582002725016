.wrapper {
  width: 100%;
  height: 36px;
  border: 1px solid #ccc;
  position: relative;
}

.item {
  height: 18px;
  width: 100%;
  background-color: var(--torq-experimental-success-color);
}

.amber {
  background-color: var(--torq-experimental-warning-color);
}

.amber.low {
  height: 12px;
}

.amber.high {
  height: 24px;
}

.red {
  background-color: var(--torq-experimental-error-color);
}

.red.low {
  height: 6px;
}

.red.high {
  height: 30px;
}

.offline {
  height: 0px;
}

.selectionOverlay {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 1;
  cursor: col-resize;
}

.unselectedArea {
  background-color: rgba(0, 0, 0, 0.253);
  height: 100%;
  width: 100%;
  pointer-events: none;
}

.selectedArea {
  width: 100%;
  height: 100%;
  cursor: grab;
}

.tick {
  width: 1px;
  height: 6px;
  background-color: black;
}
