.status {
  --state-icon-color: #000;
}

.tag {
  color: var(--state-icon-color);
}

.dot {
  background-color: var(--state-icon-color);
  width: 10px;
  height: 10px;
  border-radius: 50px;
  margin-top: 2px;
}
