.userMenuItemIcon {
  margin-right: 5px;
}

.userMenuItem {
  margin-right: 16px;
}

.userPanel {
  color: white;
  width: 100%;
  padding-right: var(--torq-experimental-rhythm-xs);
}

.userLabel {
  font-size: 14px;
}

.smoothIcon {
  transition: 0.2s;
}
