.clickable {
  cursor: pointer;
}

.clickable:hover {
  background-color: rgba(0, 0, 34, 0.055);
}

.clickable:hover:active {
  background-color: rgba(0, 0, 34, 0.109);
}

.smoothIcon {
  transition: 0.2s;
}

.card :global(.ant-card-head),
.card :global(.ant-card-head-title) {
  padding: 0px;
}
