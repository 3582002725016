#site .reduceBottomMargin {
  margin-bottom: 4px;
}

#site .addressCol {
  padding-right: 10px;
}

#site .fullWidth {
  width: 100%;
}
