.sensorName {
  display: inline-block;
  font-weight: normal;
  font-style: italic;
  font-size: 14px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  vertical-align: bottom;
}
