a:hover {
  color: var(--torq-experimental-success-color);
}

.ant-layout-content code {
  border: 1px dotted rgb(204, 148, 148);
  color: rgb(194, 113, 93);
  padding: 2px;
  background-color: rgb(255, 254, 249);
  font-size: 14px;
}

.ant-tooltip code {
  border: 1px dotted rgb(238, 226, 226);
  color: rgb(255, 172, 151);
  padding: 3px;
  background-color: rgb(78, 78, 82);
  font-size: 14px;
}

/* Required for @kunukn/react-collapse */
.collapse-css-transition {
  transition: height 280ms cubic-bezier(0.4, 0, 0.2, 1);
}
