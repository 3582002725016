.wrapper {
  min-height: 100vh;
}

.card {
  border-radius: 20px;
}

.body {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  text-align: center;
}

@media screen and (min-width: 760px) and (min-height: 582px) {
  .body {
    min-width: 452px;
    min-height: 582px;
  }
}
