.sensor {
  height: 100%;
  background-color: white;
}

.draggable {
  cursor: grab;
}

.dragging {
  opacity: 0.4;
}

.faIconWhereAntIconShouldBe {
  display: inline;
  padding-right: 5px;
}

.label {
  font-size: 0.75rem;
  font-style: italic;
  color: #777;
}

.sensorName {
  font-size: 1.2rem;
  text-align: center;
}

.sensorName.small {
  font-size: 0.9rem;
}
