.legend {
  width: 100%;
  height: 100%;
  overflow-y: auto;
  padding: 0px var(--torq-experimental-rhythm-xs);
}

.sensor {
  font-size: 17px;
  line-height: 17px;
  font-weight: bold;
  padding-bottom: 1px;
}

:global(.ant-switch).switch {
  background-color: rgb(92, 92, 92);
}
